const centralzone = [
  {
    "Sr No.": 1,
    City: "Ayodhya",
    From: "01-Oct",
    To: "01-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 2,
    City: "Raipur",
    From: "01-Oct",
    To: "01-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 3,
    City: "Patna",
    From: "01-Oct",
    To: "01-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 4,
    City: "Jaipur",
    From: "03-Oct",
    To: "04-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 5,
    City: "Lucknow",
    From: "03-Oct",
    To: "03-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 6,
    City: "Varanasi",
    From: "03-Oct",
    To: "03-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 7,
    City: "Allahabad",
    From: "05-Oct",
    To: "05-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 8,
    City: "Ghaziabad",
    From: "05-Oct",
    To: "05-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 9,
    City: "Kanpur",
    From: "05-Oct",
    To: "05-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 10,
    City: "Agra",
    From: "06-Oct",
    To: "06-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 11,
    City: "Bhopal",
    From: "07-Oct",
    To: "08-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 12,
    City: "Moradabad",
    From: "07-Oct",
    To: "07-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 13,
    City: "Bareilly",
    From: "09-Oct",
    To: "09-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 14,
    City: "Meerut",
    From: "09-Oct",
    To: "09-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 15,
    City: "Indore",
    From: "10-Oct",
    To: "11-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 16,
    City: "Jabalpur",
    From: "11-Oct",
    To: "11-Oct",
    zone: "Central",
  },
  {
    "Sr No.": 17,
    City: "Gwalior",
    From: "13-Oct",
    To: "13-Oct",
    zone: "Central",
  },
];

export default centralzone;
