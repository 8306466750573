const westzone = [
  {
    "Sr No.": 1,
    City: "Alibaug",
    From: "01-Oct",
    To: "01-Oct",
    zone: "West",
  },
  {
    "Sr No.": 2,
    City: "Nashik",
    From: "01-Oct",
    To: "01-Oct",
    zone: "West",
  },
  {
    "Sr No.": 3,
    City: "Aurangabad",
    From: "03-Oct",
    To: "03-Oct",
    zone: "West",
  },
  {
    "Sr No.": 4,
    City: "Palghar",
    From: "03-Oct",
    To: "03-Oct",
    zone: "West",
  },
  {
    "Sr No.": 5,
    City: "Raigad",
    From: "03-Oct",
    To: "03-Oct",
    zone: "West",
  },
  {
    "Sr No.": 6,
    City: "Nagpur",
    From: "05-Oct",
    To: "05-Oct",
    zone: "West",
  },
  {
    "Sr No.": 7,
    City: "Pune",
    From: "05-Oct",
    To: "06-Oct",
    zone: "West",
  },
  {
    "Sr No.": 8,
    City: "Vadodara",
    From: "05-Oct",
    To: "05-Oct",
    zone: "West",
  },
  {
    "Sr No.": 9,
    City: "Ahmedabad",
    From: "07-Oct",
    To: "08-Oct",
    zone: "West",
  },
  {
    "Sr No.": 10,
    City: "Ratnagiri",
    From: "08-Oct",
    To: "08-Oct",
    zone: "West",
  },
  {
    "Sr No.": 11,
    City: "Panjim",
    From: "10-Oct",
    To: "10-Oct",
    zone: "West",
  },
  {
    "Sr No.": 12,
    City: "Rajkot",
    From: "10-Oct",
    To: "10-Oct",
    zone: "West",
  },
  {
    "Sr No.": 13,
    City: "Thane",
    From: "10-Oct",
    To: "10-Oct",
    zone: "West",
  },
  {
    "Sr No.": 14,
    City: "Mumbai",
    From: "15-Oct",
    To: "22-Oct",
    zone: "West",
  },
];

export default westzone;
