import React from "react";
import "../glimpseSectionWrap/glimpsesection.css";
import SectionTitle from "../../../common/sectiontitletext/SectionTitle";

function GlimpseSection() {
  return (
    <section id="animeGallery">
      <SectionTitle titleText="Glimpse of ISPL T-10 Season 1" />
      <section id="glimpseSection">
        <div id="image-container">
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_45A4136.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_45A4137.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_45A4092.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_73A2999.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_73A3000.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_73A2986.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_45A4045.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_45A4021.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_45A4008.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_45A3979.webp"
              alt=""
              className="picture img-fluid 1"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6709.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6710.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6712.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6732.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6749.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6758.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6772.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6775.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6781.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6785.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6808.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6812.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6831.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div id="image-container">
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_73A2948.webp"
              alt=""
              className="picture img-fluid 12"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_73A2716.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_73A2711.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_45A3734.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/_73A2534.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0043.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0165.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0200.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0325.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0365.webp"
              alt=""
              className="picture img-fluid 22"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6858.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7015.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7188.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7252.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7254.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7283.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7588.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7608.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7611.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7623.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7631.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7652.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div id="image-container">
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0371.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0527.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0544.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/245A0559.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/2G9A9454.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/2G9A9498.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/2G9A9576.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/2G9A9684.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/2G9A9705.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/2G9A9792.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div id="image-container">
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/404A9844.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP0249.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP0286.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP2884.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP2885.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP2911.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP3728.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP3735.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP3861.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP3862.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div id="image-container">
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP3867.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP3870.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6404.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6418.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6424.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6446.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6456.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6457.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6480.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6499.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div id="image-container">
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6505.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6543.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6554.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6569.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6571.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6605.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6606.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6647.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6649.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6658.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7670.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7673.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7678.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7691.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7797.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7809.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7912.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP7966.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8034.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8035.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8053.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8054.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8140.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP6658.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8186.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8230.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8236.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8243.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8244.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8366.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP8963.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP9067.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
          <div className="image">
            <img
              src="https://my.ispl-t10.com/images/galleries/ADGP9068.webp"
              alt=""
              className="picture img-fluid"
              loading="lazy"
            />
          </div>
        </div>
      </section>
    </section>
  );
}

export default GlimpseSection;
